<template>
    <div class="content">
        <main class="section centered-content-75 is-inline-block has-text-left">
            <directus-article from="slug" :getter="id" />
        </main>
   </div>
    
</template>

<script>
import DirectusArticle from '@/components/ext/DirectusArticle.vue';

export default {
    name: 'Article',
    components: {
        DirectusArticle
    },
    data: function() {
        return {
            id: undefined
        }
    },
    beforeMount: function() {
        /*const urlSearchParams = new URLSearchParams(window.location.search);
        const params = Object.fromEntries(urlSearchParams.entries());
        this.id = params.id;*/
        this.id = this.$route.params.slug;
    }
}
</script>